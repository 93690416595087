@import '../designTokens/CssVariables.scss';

html,
body {
    background-color: $defaultBackgroundColor;
    height: 100px;
    margin: 0;
    padding: 0;
}
body {
    overscroll-behavior: contain;
}
.container {
    font-family: 'Mic 32 New Rounded W00 Medium';
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding: 0;
    align-items: center;
}
.landing-page-header {
    padding: 25px;
    position: fixed;
    top: 0;
    width: 100%;
}
.container-middle {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 0rem;
    margin-top: 4rem;
}
.landing-page-middle {
    text-align: center;
    font-weight: 600;
    font-size: x-large;
}
.landing-page-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    padding-bottom: 1rem;
    position: fixed;
    bottom: 0;
    width: 100%;
}
.landing-page-footer span {
    margin-left: 20px;
}

.rectangle-icon {
    width: 47px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}
.arrow {
    align-self: center;
}
.rectangle-1 {
    margin-bottom: 10%;
    height: 14px;
    background: $buttonBackgroundColor;
    opacity: 0.1;
    border-radius: 2px;
}
.rectangle-2 {
    margin-bottom: 10%;
    height: 14px;
    background: $buttonBackgroundColor;
    opacity: 0.3;
    border-radius: 2px;
}
.rectangle-3 {
    margin-bottom: 12%;
    height: 14px;
    background: $buttonBackgroundColor;
    opacity: 0.7;
    border-radius: 2px;
}
.rectangle-4 {
    width: 83px;
    height: 40.5px;
    background: $buttonBackgroundColor;
}
.landing-page-footer button {
    background: $buttonBackgroundColor;
    border-radius: 20px;
    font-size: 15px;
    max-width: 300px;
    width: 100%;
}
input:focus {
    border: 1px solid rgb(117, 107, 107);
    box-shadow: 0 0 10px #719ece;
    border-radius: 25px;
}
input,
select,
input:focus,
select:focus {
    outline: none !important;
    box-shadow: none;
    border-color: transparent;
}
button {
    border: none;
}
.browser-message {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
