.version-container {
    text-align: center;
    font-size: 1.5em;
    padding: 1em;
    .row-1 {
        margin-bottom: 1em;
    }
    .row-4 {
        margin-top: 1em;
    }
}
