@import '../designTokens/CssVariables.scss';

.my-list-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    .my-list-contents {
        text-align: center;
        .iternary {
            font-size: 1.5em;
        }
        .baseline-card-header {
            font-size: 1.2em;
            font-family: 'Source Sans Pro';
            display: flex;
            justify-content: space-around;
        }
        .baseline-card {
            background: $flightCardBackgroundColor;
            .baseline-card-row-1 {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 1.2em;

                font-family: 'Mic 32 New Rounded W00 light';
                .currency {
                    margin-top: 0.1em;
                    font-size: 0.8em;
                }
                .baseline-price {
                    margin-top: 0.2em;
                }
            }
            .baseline-card-row-2 {
                display: flex;
            }
        }
        .flight-card {
            display: flex;
            background: $baselineflightCardBackgroundColorColor;
            width: 45vw;
            margin: 3vw;
            border-radius: 0.7em;
            padding: 2vw;
            .card-row-1 {
                font-size: 0.8em;
                display: flex;
                justify-content: space-between;
                .airline-logo img {
                    width: 1.5rem;
                }
            }
            .card-row-2-1 {
                gap: 0.9em;
                font-size: 0.7em;
                display: flex;
                justify-content: space-between;
            }
            .card-row-2-2 {
                font-size: 0.7em;
                gap: 1em;
                display: flex;
                justify-content: space-between;
            }
            .card-row-3 {
                font-size: 0.7em;
                display: flex;
                justify-content: space-between;
            }
        }
    }
    .scroll {
        height: 54vh;
        overflow-y: scroll;
        .offer-card {
            margin-top: 1em;
            .offer-card-row-1 {
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .offer-card-row-3 {
                display: flex;
            }
        }
        .my-list-btn {
            display: flex;
            justify-content: space-around;
            .my-list-remove-btn {
                background: $removeButtonBackgroundColor;
                font-size: 0.8em;
                border-radius: 1em;
                color: white;
                border: none;
                width: 5em;
            }
            .my-list-book-btn {
                background: $bookButtonBackgroundColor;
                font-size: 0.8em;
                width: 5em;
                border-radius: 1em;
                color: $buttonLabelColor;
                border: none;
            }
        }
    }

    .my-list-footer-btn {
        border: none;
        border-radius: 1em;
        color: $buttonLabelColor;
        background: $buttonBackgroundColor;
    }
}
.flight-image {
    width: 100%;
    border-radius: 0.5em;
    object-fit: contain;
    height: 25vh;
}
.no-image {
    text-align: center;
}
.rating-label {
    font-family: 'Source Sans Pro';
    margin-top: 0.5em;
}
.rating-progress {
    display: flex;
    gap: 1em;
    align-items: center;
    .progress-bar {
        width: 90%;
    }
}
.row-0 {
    background-color: $headerBackgroundColor;
    display: flex;
    padding: 0.5em;
    align-items: baseline;
    justify-content: space-between;
    .row-0-1 {
        border: none;
        border-radius: 1em;
        color: $buttonLabelColor;
        background: $buttonBackgroundColor;
    }
}
