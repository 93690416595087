@import '../designTokens/CssVariables.scss';

.modal-container {
    position: absolute;
    background: white;
    top: 30%;
    padding: 1.5em;
}
.Full-img {
    width: 100%;
}

.modal-close {
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0.5;
    font-size: 1em;
}
.modal-close:hover {
    opacity: 1;
}

.child-image {
    height: 7vh;
    object-fit: cover;
}
