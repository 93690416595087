@import '../designTokens/CssVariables.scss';

.details-container {
    display: flex;
    flex-wrap: wrap;
    .details {
        flex: 0 0 50%;
        &__heading {
            font-family: 'Mic 32 New Rounded W00 Light';
            font-size: 4.3vw;
            font-style: normal;
            font-weight: normal;
            line-height: 150%;
            letter-spacing: 0.01em;
            text-transform: uppercase;
            color: $defaultTextColor;
            margin-left: 4vw;
            margin-top: 2.7vh;
        }
        &__selector {
            display: flex;
            background-color: $findFlightFieldBackgroundColor;
            border-radius: 25px;
            color: $defaultTextColor;
            height: 6.5vh;
            padding: 4px 4px;
            margin-top: 1vh;
            align-items: center;
            font-family: 'Source Sans Pro';
            font-style: normal;
            font-weight: normal;
            font-size: 3.5vw;
            line-height: 100%;
            width: 47vw;

            &__code {
                display: inline-flex;
            }
            input {
                color: $defaultTextColor;
                background-color: inherit;
                border: none;
                -webkit-appearance: textfield;
                -moz-appearance: textfield;
                width: 100%;
            }

            &__text {
                display: flex;
                width: 30vw;
                width: 34.3vw;
            }

            &__airline {
                display: flex;
                width: 30vw;
            }
        }
    }
}
.footer {
    width: calc(100% - 4vw);
    border-radius: 24px;
    bottom: 6px;
    position: sticky;
    margin-top: 3vh;
    display: flex;
    flex: auto;

    .icon-right {
        margin-left: 5px;
        width: 3vw;
        padding-bottom: 1.6px;
    }
    .booking-button {
        background: $buttonBackgroundColor;
        padding: 15px;
        color: $buttonLabelColor;
        border-radius: 56px;
        width: 100%;
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: bold;
        font-size: 3.5vw;
        line-height: 100%;
        text-align: center;
        text-transform: uppercase;
    }
}
select {
    background: url(../assets/icons/CircleDownArrow.svg) no-repeat right;
    background-position: calc(100% - 0.75rem) center !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    padding-right: 2rem !important;
    width: 100%;
    background-size: 4vw;
    border: none;
    color: $defaultTextColor;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: normal;
    font-size: 3, 5vw;
    line-height: 100%;
}
.error {
    font-size: 10px;
    color: $errorColor;
    margin-left: 20px;
}
.date {
    padding-right: 5px;
}

.details__selector__icon {
    height: 100%;
}
.selected-text {
    color: $buttonLabelColor !important;
}
