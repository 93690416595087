@import '../designTokens/CssVariables.scss';

.compare-page-container {
    background: $comparePageBackgroundColor;
}
.column {
    float: left;
    width: 50%;
    padding: 10px;
}
.white-background {
    background: white !important;
}

.compare-row:after {
    content: '';
    display: table;
    clear: both;
}

.baseline-day {
    font-size: 3.4vw;
}
.baseline-date {
    font-size: 3.4vw;
}
.compare-row {
    width: 100%;
    position: relative;
    padding-bottom: 2rem;
}
.baseline-container {
    font-family: 'Source Sans Pro';
    margin-top: 1vh;
    margin-bottom: 3vh;
    font-size: 0.8em;
}
.baseline-text-img {
    display: flex;
}
.compare-img {
    width: 23vw;
    height: 13vh;
    object-fit: cover;
    border-radius: 1vh;
}
.compare-no-img {
    background-origin: content-box;
    padding: 3px;
}
.baseline-text {
    width: 50%;
}
.baseline-seat {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 1vh;
    align-items: baseline;
}
.menu-container {
    background-color: $headerBackgroundColor;
    display: flex;
    justify-content: space-between;
    height: 6vh;
    align-items: center;
    padding: 1vh;
    padding-bottom: 1vh;
    .menu-icon-text {
        padding: 1vh;
        width: 8vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 1.4vh;
        .menu-icon {
            width: 3vh;
        }
    }
    .my-list-button button {
        background: none;
        padding: 0;
        cursor: pointer;
    }
}
.city-container {
    display: flex;
    justify-content: center;
    padding: 14px;
    height: 9vh;
}
.column-trip {
    background-color: $yourRoundTripBackgroundColor;
    text-align: center;
    font-size: 1em;
}
.compare-price {
    height: 7.5vh;
    display: flex;
    justify-content: space-between;
    .airline {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .airline-name {
        font-size: 0.5em;
        clear: both;
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
    }
    .airline-logo {
        width: 8vw;
    }
    .details {
        font-size: 1vh;
        clear: both;
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
    }
    .price-details {
        text-align: right;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
    .price-details-row-2 {
        font-size: 0.5em;
        text-align: center;
    }
}
@media (min-width: 320px) {
    .cabin {
        font-size: 0.8em;
    }
    .price-details {
        font-size: 0.8em;
    }
}
@media (min-width: 801px) {
    .cabin {
        font-size: 1.3em;
    }
    .price-details {
        font-size: 1.3em;
    }
}
.compare-line {
    background-color: $flightCardTitleBackgroundColor;
    height: 4vh;
    font-size: 2vh;
    text-align: center;
    margin-top: 2vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.guidance {
    &__menu {
        display: flex;
        position: absolute;
        align-items: baseline;
        top: 1vh;
        left: 6vw;
        .menu-icon {
            width: 13vw;
        }
        .menu-text {
            margin-left: 1.6vw;
            font-size: 3.4vw;
        }
    }
    &__list {
        display: flex;
        position: absolute;
        align-items: baseline;
        top: 1vh;
        right: 6vw;
        .list-icon {
            width: 13vw;
        }
        .list-text {
            margin-right: 1.6vw;
            text-align: end;
            font-size: 3.4vw;
            font-size: 3.4vw;
        }
    }
    &__checkout {
        display: flex;
        position: absolute;
        align-items: baseline;
        top: 21vh;
        right: 12vw;
        .checkout-icon {
            width: 13vw;
        }
        .checkout-text {
            margin-right: 1.6vw;
            text-align: end;
            font-size: 3.4vw;
        }
    }
    &__scroll {
        display: flex;
        position: absolute;
        align-items: baseline;
        top: 50vh;
        right: 4vw;
        .scroll-icon {
            width: 13vw;
        }
        .scroll-text {
            margin-right: 1.6vw;
            text-align: end;
            font-size: 3.4vw;
        }
    }
    &__save {
        display: flex;
        position: absolute;
        align-items: flex-end;
        bottom: 2vh;
        left: 7vw;
        .save-icon {
            width: 13vw;
        }
        .save-text {
            margin-left: 1.6vw;
            text-align: start;
            margin-bottom: 6vw;
            font-size: 3.4vw;
        }
    }
    &__center {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        -moz-transform: translateX(-50%) translateY(-50%);
        -webkit-transform: translateX(-50%) translateY(-50%);
        background: $buttonBackgroundColor;
        border-radius: 56px;
        width: 35vw;
        text-align: center;
        padding: 4px;
        color: $buttonLabelColor;
    }
    &__book {
        display: flex;
        align-items: flex-end;
        position: absolute;
        bottom: 2vh;
        right: 7vw;
        .book-icon {
            width: 13vw;
        }
        .book-text {
            margin-right: 1.6vw;
            text-align: end;
            margin-bottom: 6vw;
            font-size: 3.4vw;
        }
    }
}
.compare-buttons {
    bottom: 0vh;
    display: flex;
    justify-content: center;
    width: 100%;
    position: fixed;
    padding: 0.5rem;
    background-color: $defaultBackgroundColor;
    Button {
        margin-right: 0.3rem;
        margin-left: 0.3rem;
        padding: 15px;
        border-radius: 56px;
        width: 100%;
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: bold;
        font-size: 3.5vw;
        line-height: 100%;
        text-align: center;
        text-transform: uppercase;
        width: 10rem;
        border: none;
        border-radius: 40px;
        color: $buttonLabelColor;
        background: $buttonBackgroundColor;
    }
}
.baseline-img {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 0vh;
    .image-preview {
        width: 6vw;
        margin-top: -4vh;
        margin-right: 1vh;
    }
}

.pop-body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.pop-body b {
    color: $betterPriceColor;
}

.pop-body-content {
    display: flex;
    justify-content: space-between;
}

.pop-body-content b {
    background-color: $betterComfortBackgroundColor;
    color: $betterComfortColor;
    padding: 0.2rem;
    font-size: 0.6rem;
}

.toast-container {
    margin-top: 16vh;
    background-color: $toastBackgroundColor;
    .toast {
        width: max-content;
    }
}

.progress-bar {
    background-color: $progressBarBackgroundColor;
    height: 0.3vh;
}

.fetching {
    font-size: 5vw;
    align-items: center;
    align-content: center;
}

.fetching span {
    font-size: 50px;
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
}

.fetching span:nth-child(2) {
    animation-delay: 0.2s;
}

.fetching span:nth-child(3) {
    animation-delay: 0.4s;
}
.back-button {
    border: none;
    border-radius: 1em;
    color: $buttonLabelColor;
    background: $buttonBackgroundColor;
}

@keyframes blink {
    0% {
        opacity: 0.2;
    }
    20% {
        opacity: 1;
    }
    100% {
        opacity: 0.2;
    }
}

.iternary {
    display: flex;
    justify-content: center;
    gap: 1em;
    align-items: center;
    font-size: 1.3em;
    margin: 1vh;
    font-family: 'Mic 32 New Rounded W00 light';
}
.rating {
    display: flex;
    justify-content: space-between;
    font-size: 0.8em;
}
