.carousel-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.carousel-wrapper {
    display: flex;
    width: 100%;
    position: relative;
}

.carousel-content-wrapper {
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.carousel-content {
    display: flex;
    transition: all 250ms linear;
    -ms-overflow-style: none; /* hide scrollbar in IE and Edge */
    scrollbar-width: none; /* hide scrollbar in Firefox */
}

/* hide scrollbar in webkit browser */
.carousel-content::-webkit-scrollbar,
.carousel-content::-webkit-scrollbar {
    display: none;
}

.carousel-content > * {
    width: 100%;
    flex-shrink: 0;
    flex-grow: 1;
    display: table-cell;
}

.carousel-content.frame-size-2 > * {
    width: 50%;
}

.carousel-content.frame-size-3 > * {
    width: calc(100% / 3);
}

.carousel-content.frame-size-4 > * {
    width: calc(100% / 4);
}

.left-arrow,
.right-arrow {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    border: 1px solid darkgray;
    border-radius: 50%;
    padding: 0.1rem 0.5rem;
}

.left-arrow {
    left: 0px;
}

.right-arrow {
    right: 0px;
}

.no-item {
    text-align: center;
}
