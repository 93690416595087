@font-face {
    font-family: 'Mic 32 New Rounded W00 light';
    src: url('../assets/fonts/mic-32-new-rounded-light/a64c62a33f66eac7dc37a03db6860144.eot'); /* IE9*/
    src: url('../assets/fonts/mic-32-new-rounded-light/a64c62a33f66eac7dc37a03db6860144.eot?#iefix')
            format('embedded-opentype'),
        /* IE6-IE8 */
            url('../assets/fonts/mic-32-new-rounded-light/a64c62a33f66eac7dc37a03db6860144.woff2')
            format('woff2'),
        /* chrome、firefox */
            url('../assets/fonts/mic-32-new-rounded-light/a64c62a33f66eac7dc37a03db6860144.woff')
            format('woff'),
        /* chrome、firefox */
            url('../assets/fonts/mic-32-new-rounded-light/a64c62a33f66eac7dc37a03db6860144.ttf')
            format('truetype'),
        /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
            url('../assets/fonts/mic-32-new-rounded-light/a64c62a33f66eac7dc37a03db6860144.svg')
            format('svg'); /* iOS 4.1- */
}

@font-face {
    font-family: 'Mic 32 New Rounded W00 Medium';
    src: url('../assets/fonts/mic-32-new-rounded-medium/1ca73c30ca7bd2ac2e90b66f5a52a8b9.eot'); /* IE9*/
    src: url('../assets/fonts/mic-32-new-rounded-medium/1ca73c30ca7bd2ac2e90b66f5a52a8b9.eot?#iefix')
            format('embedded-opentype'),
        /* IE6-IE8 */
            url('../assets/fonts/mic-32-new-rounded-medium/1ca73c30ca7bd2ac2e90b66f5a52a8b9.woff2')
            format('woff2'),
        /* chrome、firefox */
            url('../assets/fonts/mic-32-new-rounded-medium/1ca73c30ca7bd2ac2e90b66f5a52a8b9.woff')
            format('woff'),
        /* chrome、firefox */
            url('../assets/fonts/mic-32-new-rounded-medium/1ca73c30ca7bd2ac2e90b66f5a52a8b9.ttf')
            format('truetype'),
        /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
            url('../assets/fonts/mic-32-new-rounded-medium/1ca73c30ca7bd2ac2e90b66f5a52a8b9.svg')
            format('svg'); /* iOS 4.1- */
}
@font-face {
    font-family: 'Mic 32 New Rounded W00 Regular';
    src: url('../assets/fonts/mic-32-new-rounded-regular/fa0d8d633e0dc6e3fc0012171e402030.eot'); /* IE9*/
    src: url('../assets/fonts/mic-32-new-rounded-regular/fa0d8d633e0dc6e3fc0012171e402030.eot?#iefix')
            format('embedded-opentype'),
        /* IE6-IE8 */
            url('../assets/fonts/mic-32-new-rounded-regular/fa0d8d633e0dc6e3fc0012171e402030.woff2')
            format('woff2'),
        /* chrome、firefox */
            url('../assets/fonts/mic-32-new-rounded-regular/fa0d8d633e0dc6e3fc0012171e402030.woff')
            format('woff'),
        /* chrome、firefox */
            url('../assets/fonts/mic-32-new-rounded-regular/fa0d8d633e0dc6e3fc0012171e402030.ttf')
            format('truetype'),
        /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
            url('../assets/fonts/mic-32-new-rounded-regular/fa0d8d633e0dc6e3fc0012171e402030.svg')
            format('svg'); /* iOS 4.1- */
}
$buttonBackgroundColor: #8162ff;
$defaultBackgroundColor: #e6e6ed;
$defaultTextColor: #4f545f;
$errorColor: red;
$dayNightBackgroundColor: #a60649;
$hrColor: #818186;
$headerBackgroundColor: #9e8f8f5e;
$betterPriceColor: #cf7200;
$betterComfortColor: black;
$betterComfortBackgroundColor: #08a1086b;
$progressBarBackgroundColor: #d1d1d1;
$flightCardBackgroundColor: #8080803d;
$removeButtonBackgroundColor: #cc2626b3;
$bookButtonBackgroundColor: #62cc2f;
$priceColor: #078000d4;
$findFlightSwitchButtonBackgroundColor: #ffffff;
$buttonLabelColor: #ffffff;
$findFlightFieldBackgroundColor: #e9ebf2;
$baselineBackButtonBackgroundColor: #ffffff;
$baselineflightCardBackgroundColorColor: #ffffff;
$comparePageBackgroundColor: #ffffff;
$yourRoundTripBackgroundColor: skyBlue;
$flightCardTitleBackgroundColor: #e2d5d57c;
$toastBackgroundColor: #ffffff;
$flightDetailsBackgroundColor: #ffffff;
$flightDetailsPriceSectionBackgroundColor: #ffffff;
