@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap');
@import '../designTokens/CssVariables.scss';

.baseline-root {
    width: 100%;
    height: 100%;
}
.baseline-header {
    text-align: center;
    color: $defaultTextColor;
    margin: 1rem 0 0 0;
    font-size: 5.5vw;
    font-family: 'Mic 32 New Rounded W00 Regular';
}
.baseline-middle {
    width: 100%;
    padding-bottom: 4rem;
}
.baseline-header span {
    color: $buttonBackgroundColor;
    font-family: 'Mic 32 New Rounded W00 Medium';
}
.baseline-footer {
    border-radius: 0em;
    text-align: center;
    padding: 0 0 0.5rem 0;
    width: 100%;
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: center;
    div {
        margin-right: 0.3rem;
        margin-left: 0.3rem;
        button {
            padding: 15px;
            border-radius: 56px;
            width: 100%;
            font-family: 'Source Sans Pro';
            font-style: normal;
            font-weight: bold;
            font-size: 3.5vw;
            line-height: 100%;
            text-align: center;
            text-transform: uppercase;
            width: 10rem;
        }
    }
}
.baseline-footer-back-btn {
    background: $baselineBackButtonBackgroundColor;
    width: inherit;
    border-radius: 40px;
    color: $defaultTextColor;
    border: none;
}
.baseline-footer-confirm-btn {
    width: inherit;
    border: none;
    border-radius: 40px;
    color: $buttonLabelColor;
    background: $buttonBackgroundColor;
}
.baseline-middle-outbound {
    justify-content: center;
    display: flex;
    flex-direction: row;
}
.src-airline {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-size: 3vw;
    color: $defaultTextColor;
    &.right {
        text-align: right;
    }
}
.src-code {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 700;
    font-size: 7vw;
    &.right {
        text-align: right;
    }
}
.flight-meta {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
}
.baseline-middle-outbound-line {
    width: 50vw;
}
.baseline-middle-inbound-line {
    margin-left: -2vw;
    width: 50vw;
}
.baseline-middle-outbound-plane {
    margin-top: -13vw;
    width: 7vw;
    margin-left: 25vw;
}
.baseline-middle-inbound-plane {
    margin-top: -13vw;
    width: 7vw;
    margin-left: 23vw;
}
.switch {
    color: $defaultTextColor;
    font-family: 'Mic 32 New Rounded W00 Regular';
    margin-top: -4vw;
    font-size: 4vw;
    opacity: 0.5;
    margin-left: 2vw;
    text-align: center;
}
.des-code {
    opacity: 0.2;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 700;
    font-size: 7vw;
    color: $defaultTextColor;
    &.right {
        text-align: right;
    }
}
.des-airline {
    opacity: 0.2;
    color: $defaultTextColor;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-size: 3vw;
    &.right {
        text-align: right;
    }
}
.baseline-list-card {
    border-radius: 4vw;
    display: flex;
    flex-direction: row;
}
.baseline-list-card-photo {
    width: auto;
    margin-top: 2vw;
    border-radius: 4vw;
    margin-bottom: 2vw;
}
.card-body {
    display: flex;
}
.baseline-list-card-class-icon {
    width: 7vw;
    align-self: flex-start;
}
.baseline-middle-flight-card {
    display: flex;
    background: $baselineflightCardBackgroundColorColor;
    width: 94%;
    margin: 3vw;
    border-radius: 0.7em;
    padding: 2vw;
}
.flight-card-list {
    display: flex;
    flex-direction: column;
    width: 100%;

    margin-left: 2vw;
}
.flight-card-list-1 {
    justify-content: space-between;
    display: flex;
    font-family: 'Mic 32 New Rounded W00 Regular';
    font-size: 1em;
}
.flight-card-list-2 {
    font-family: 'Source Sans Pro', sans-serif;
    display: flex;
    flex-direction: column;
}
.flight-card-list-3 {
    color: $defaultTextColor;
    font-family: 'Source Sans Pro', sans-serif;
    display: flex;
    justify-content: space-between;
}
.flight-card-list-2 span {
    background: $dayNightBackgroundColor;
    padding: 0 2%;
    border-radius: 3vw;
}
.flight-card-list-2 img {
    width: 3vw;
    margin-bottom: 0.5vw;
    padding: 5%;
}
.flight-card-list-2-date {
    font-size: 4vw;
}
.flight-card-list-2-route {
    display: flex;
    justify-content: space-between;
    font-size: 3.3vw;
}
hr {
    margin: 0.5rem 0;
    background: $hrColor;
}
.middle-inbound-image {
    transform: scaleX(-1);
    padding-top: 0.5rem;
}
.middle-outbound-image {
    padding-top: 0.5rem;
}
.price {
    font-family: 'Mic 32 New Rounded W00 Regular';
    color: $defaultTextColor;
    display: flex;
    justify-content: flex-end;
    margin-top: 2vh;
    padding-right: 3vh;
    align-items: center;
    gap: 1rem;
}
.price span {
    opacity: 0.5;
}
.price b {
    font-family: 'Mic 32 New Rounded W00 Medium';
    font-size: 1.8rem;
}
.price .currency {
    margin-right: -0.9rem;
}
.inbound {
    margin-top: 5vh;
}
.loading {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flight-gallery {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 0vh;
    width: 80%;
    height: auto;
}

.gallery-img {
    border-radius: 0.5rem;
    width: 100%;
    height: 100%;
}
.image-preview {
    width: 20px;
    margin-top: -25px;
    margin-right: 5px;
}

.loading img {
    margin-top: 5rem;
    width: 6vw;
    height: 6vw;
}

.gallery-not-available {
    width: 40vw;
    height: 15vh;
    border-radius: 0.5rem;
}

.not-available {
    background-repeat: no-repeat;
    background-size: 1rem;
    background-position-y: bottom;
    background-size: 1.2rem;
    background-image: url('../assets/icons/DiagonalLine.png');
}

.airline-logo img {
    width: 1.5rem;
}
