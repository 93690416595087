@import '../designTokens/CssVariables.scss';

.navigation {
    color: white;
    height: 44px;
    position: relative;
    -webkit-user-select: none;
    user-select: none;
    z-index: 10;
    &__list {
        float: right;
    }
    &__item {
        background: none;
        font-size: 1.4vh;
        cursor: pointer;
        display: block;
        float: left;
        height: 44px;
        position: relative;
        transition: all 0.2s;
        width: 44px;
        &.m-active {
            background: #1378c0;
        }
        &-icon {
            content: '';
            display: block;
            height: 21px;
            margin: 12px auto 0;
            width: 21px;
        }
    }
    &__title {
        font-size: 18px;
        line-height: 20px;
        margin: 0 0 0 44px;
        padding: 14px 0 0 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.aside {
    background: #e9ebf2;
    color: white;
    font-size: 17px;
    height: 100vh;
    left: -110%;
    padding-top: 10%;
    position: absolute;
    transition: left 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    width: 100%;
    z-index: 2;
    top: 1px;
    &__item {
        & + & .aside__text {
            border-top: 1px solid #262626;
        }
    }
    &__link {
        background: #fbfbfb3d;
        color: black;
        display: block;
        font-size: 17px;
        margin: 0;
        position: relative;
        transition: all 0.2s;
        width: 100%;
        &:hover {
            background: white;
            color: black;
        }
    }
    &__text {
        display: block;
        line-height: 21px;
        min-height: 21px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.m-show-aside {
    left: 0%;
}
