@import '../designTokens/CssVariables.scss';

.trip-details {
    padding-left: 8px;
    padding-right: 8px;
    display: flex;
    flex-direction: column;
    width: 100%;
    .find-your-flight {
        position: relative;
        background-color: $defaultBackgroundColor;
        &__heading {
            margin-top: 12px;
            text-align: center;
            font-family: 'Mic 32 New Rounded W00 Light';
            font-style: normal;
            font-weight: normal;
            font-size: 5vw;
            margin-bottom: 6px;
            margin-left: 4vw;
        }
        &__buttons-toolbar {
            display: flex;
            height: 8vh;
            &__left-group {
                display: flex;
                flex: 1;
                justify-content: center;
                align-items: center;
                border-radius: 56px 2px 2px 56px;
                background-color: $findFlightSwitchButtonBackgroundColor;
                color: $defaultTextColor;
                .flight-number {
                    width: 100%;
                    text-align: center;
                    font-family: 'Source Sans Pro';
                    font-style: normal;
                    font-weight: bold;
                    font-size: 3.5vw;
                    line-height: 100%;
                    &__hashtag-img {
                        margin-right: 6px;
                        vertical-align: inherit;
                        width: 3vw;
                    }
                }
            }
            &__right-group {
                border-radius: 2px 56px 56px 2px;
                display: flex;
                flex: 1;
                justify-content: center;
                align-items: center;
                background-color: $findFlightSwitchButtonBackgroundColor;
                color: $defaultTextColor;
                .flight-details {
                    width: 100%;
                    text-align: center;
                    font-family: 'Source Sans Pro';
                    font-style: normal;
                    font-weight: bold;
                    font-size: 3.5vw;
                    line-height: 100%;
                    &__search-img {
                        margin-right: 6px;
                        vertical-align: inherit;
                        width: 3vw;
                    }
                }
            }
        }
        &__trip-heading {
            font-family: 'Mic 32 New Rounded W00 Light';
            font-size: 4.3vw;
            font-style: normal;
            font-weight: normal;
            line-height: 150%;
            letter-spacing: 0.01em;
            text-transform: uppercase;
            color: $defaultTextColor;
            margin-top: 2.7vh;
            margin-left: 4vw;
        }
        &__select-trip {
            display: flex;
            background-color: $findFlightFieldBackgroundColor;
            border-radius: 25px;
            color: $defaultTextColor;
            height: 6.5vh;
            padding: 4px 4px;
            margin-top: 1vh;
            .filler {
                flex-grow: 1;
                text-align: center;
            }
            &__right-arrow {
                margin-right: 12px;
            }
            &__text {
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: 'Source Sans Pro';
                font-style: normal;
                font-weight: normal;
                font-size: 3.5vw;
                line-height: 100%;
                color: $defaultTextColor;
            }
            &__down-arrow {
                margin-right: 12px;
                width: 20px;
            }
        }
    }

    .number {
        display: flex;
        margin-right: 15px;
        .minus {
            width: 3vw;
        }
        input[type='text'],
        textarea {
            width: 10vw;
            background-color: inherit;
            border: none;
            margin: 0px 12px;
            opacity: 0.5;
            text-align: center;
            font-size: 3.5vw;
        }
        .plus {
            width: 3vw;
        }
    }
    .filler {
        flex-grow: 1;
        text-align: center;
    }
    .down-arrow {
        margin-right: 12px;
        width: 20px;
    }
    select {
        background: url(../assets/icons/CircleDownArrow.svg) no-repeat;
        background-position: calc(100% - 0.75rem) center !important;
        -moz-appearance: none !important;
        -webkit-appearance: none !important;
        appearance: none !important;
        padding-right: 2rem !important;
        width: 100%;
        background-size: 3vw;
        background-color: inherit;
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: normal;
        font-size: 3, 5vw;
        line-height: 100%;
        border-radius: 25px;
    }
}
.selected {
    background: $buttonBackgroundColor !important;
    color: $buttonLabelColor !important;
}
.selected-icon {
    filter: brightness(0) invert(1);
}
