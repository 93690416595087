@import '../designTokens/CssVariables.scss';

.flight-details-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: $flightDetailsBackgroundColor;
    gap: 0.5em;
    padding: 0.5rem;
    .row-0 {
        .row-0-1 {
            border: none;
            border-radius: 1em;
            color: $buttonLabelColor;
            background: $buttonBackgroundColor;
        }
        background-color: $headerBackgroundColor;
        display: flex;
        padding: 0.5em;
        align-items: baseline;
        justify-content: space-between;
    }
    .row-2 {
        padding: 0.5em;
    }
    .row-3 {
        display: flex;
        padding: 0.2em;
        background: $flightDetailsPriceSectionBackgroundColor;
        justify-content: space-between;
        .row-3-1 {
            display: flex;
        }
        .row-3-1-1 img {
            width: 3.5em;
        }
        .row-3-1-2 {
            align-self: flex-end;
        }
        .row-3-2 {
            align-self: flex-end;
            color: $priceColor;
            .row-3-2-1 {
                font-size: 0.8em;
                display: flex;
                justify-content: center;
            }
            .row-3-2-2 {
                font-size: 1.5em;
            }
        }
    }
    .row-4 {
        display: flex;
        flex-direction: column;
        font-family: 'Mic 32 New Rounded W00 light';
        .row-4-1 {
            font-size: 0.8em;
            font-weight: 600;
        }
        .row-4-2 {
            font-weight: 600;
        }
        .row-4-3 {
            display: flex;
            column-gap: 1em;
        }
    }
}
